import {Component} from '@angular/core';
import {Contact} from '../../../../models/Contact';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../_services/api.service';
import {map} from 'rxjs/operators';

@Component({
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss']
})
export class AddClientModalComponent {
  client: Contact;
  isEdit = false;

  constructor(public bsModalRef: BsModalRef, private modalSvc: BsModalService, private apiSvc: ApiService) {
    this.client = new Contact();
  }

  saveChanges() {

    let apiObservable = this.apiSvc.createContact(this.client);

    if (this.isEdit) {
      apiObservable = this.apiSvc.updateContact(this.client).pipe(map(() => this.client));
    }

    apiObservable.subscribe((contact) => {
      this.client = contact;
      this.modalSvc.setDismissReason('changes-saved');
      this.bsModalRef.hide();
    }, (error) => {
      this.modalSvc.setDismissReason('failed');
      this.bsModalRef.hide();
    })


  }
}
