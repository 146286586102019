import {CourseRunBooking} from './CourseRunBooking';
import {Contact} from './Contact';
import {SubCourse} from './SubCourse';
import {UUID} from '../app/utils/Utils';
import {Membership} from './Membership';
import {Serializable} from './Serializable';
import BigNumber from 'bignumber.js';

export class BookingParticipant implements Serializable<BookingParticipant> {
  id: string | number = UUID();
  booking: CourseRunBooking;
  contact: Contact = null;
  subCourse: SubCourse = null;
  status: BookingParticipantStatus = 'confirmed';
  notes: string;
  membership: Membership;

  constructor() {
  }

  getPrice() {
    if (!this.membership) {
      return this.booking.courseRun.course.price;
    }

    const coursePrice = new BigNumber(this.booking.courseRun.course.price);

    return +coursePrice.minus(coursePrice.times(new BigNumber(this.membership.getCourseDiscount(this.booking.courseRun.course)).dividedBy(100)).toFixed(2));
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.booking = new CourseRunBooking().deserialize(input.booking);
    this.contact = new Contact().deserialize(input.contact);
    this.subCourse = new SubCourse().deserialize(input.subCourse);
    this.status = input.status;
    this.notes = input.notes;
    this.membership = new Membership().deserialize(input.membership);

    return this;
  }
}

export type BookingParticipantStatus = 'confirmed' | 'attended' | 'cancelled' | 'transferred' | 'no_show';
