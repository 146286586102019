import {NgModule} from '@angular/core';

import {routing} from './pages.routing';

import {Pages} from './pages.component';
import {CommonModule} from '@angular/common';
import {NgaModule} from '../theme/nga.module';

@NgModule({
  imports: [CommonModule, NgaModule, routing],
  declarations: [Pages]
})
export class PagesModule {
}
