import {HttpParams} from '@angular/common/http';

export class Utils {
  colourIsLight(r, g, b) {
    // Counting the perceptive luminance
    // human eye favors green color...
    let a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return (a < 0.5);
  }

  public static toTitleCase(value) {

  }
}

export function toTitleCase(value) {
  return value.replace(/([^\W_]+[^\s_]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }).replace('_', ' ');
}

export function pad(num: number, padAmount: number) {
  let ret: string = num.toString(16);
  while (ret.length < padAmount) {
    ret = '0' + ret;
  }
  return ret;
}

function pad4(num: number): string {
  return pad(num, 4);
}

function random4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function UUID(): string {
  if (typeof (window) !== 'undefined' && typeof (window.crypto) !== 'undefined' && typeof (window.crypto.getRandomValues) !== 'undefined') {
    // If we have a cryptographically secure PRNG, use that
    // http://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
    let buf: Uint16Array = new Uint16Array(8);
    window.crypto.getRandomValues(buf);
    return (pad4(buf[0]) + pad4(buf[1]) + '-' + pad4(buf[2]) + '-' + pad4(buf[3]) + '-' + pad4(buf[4]) + '-' + pad4(buf[5]) + pad4(buf[6]) + pad4(buf[7]));
  } else {
    // Otherwise, just use Math.random
    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    // https://stackoverflow.com/questions/11605068/why-does-jshint-argue-against-bitwise-operators-how-should-i-express-this-code
    return random4() + random4() + '-' + random4() + '-' + random4() + '-' +
      random4() + '-' + random4() + random4() + random4();
  }
}

export function compareFunc(key): (a,b)=> boolean {
  return (a, b) => {
    return (a === b) || (!a && !b) || (a && b && a[key] === b[key]);
  }
}

export function base64ToBlob(base64, mime) {
  mime = mime || '';
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];

  for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    const slice = byteChars.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: mime});
}

export function toHttpParams(params) {
  if (!params) {
    return {}
  }
  return Object.getOwnPropertyNames(params)
    .reduce((p, key) => {
      let value = params[key];
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      return p.set(key, value);
    }, new HttpParams());
}
