import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImagePickerComponent),
      multi: true
    }
  ]
})
export class ImagePickerComponent implements OnInit, ControlValueAccessor {

  apiURL = environment.apiUrl;
  onChange;
  onTouched;
  disabledState;
  private _images;

  @Input() set images(images) {
    this._images = images;

    if (images && images.length > 0) {
      this.selectedImage = 0;
      this.onChange(images[0]);
    }
  }

  get images() {
    return this._images;
  }

  selectedImage: number;

  constructor() {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledState = isDisabled;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.selectedImage = null;
      return;
    }
    if (!this.images) return;
    const foundIdx = this.images.findIndex(obj);
    if (foundIdx > -1) {
      this.selectedImage = foundIdx;
    }
  }

  selectImage(index) {
    this.selectedImage = index;
    this.onChange(this.images[index]);
  }
}
