import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';

import {ApiService} from '../../../_services/api.service';
import {Contact} from '../../../../models/Contact';
import {CourseRunBooking} from '../../../../models/CourseRunBooking';
import {compareFunc} from '../../../utils/Utils';
import {catchError, first, map, startWith, switchMap, take, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {ParticipantStatusModalComponent} from '../../courseCalendar/participant-status-modal/participant-status-modal.component';
import {BookingParticipant} from '../../../../models/BookingParticipant';
import {BsModalService} from 'ngx-bootstrap';
import {Membership} from '../../../../models/Membership';
import 'moment-timezone/index';
import {ToastrService} from 'ngx-toastr';
import {GiftVoucher} from '../../../../models/GiftVoucher';
import {FormControl} from '@angular/forms';
import {SalesCategory} from '../../../../models/SalesCategory';
import {SystemCompanyService} from '../../../_services/system-company.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  compareFunc = compareFunc;
  salesCategorySelector = new FormControl();
  @ViewChild('contactForm', {static: false}) contactForm;
  isRequesting = false;
  @Input() isClient: boolean = false;
  @Input() isModal: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() buttonValue: string = 'Save Changes';
  @Output('changesSaved') changesSaved = new EventEmitter<Contact>();

  memberships$: Observable<Membership[]>;
  subscriptions: Subscription[] = [];
  uploadOptions = {
    type: 'uploadAll',
    url: 'https://api.lagoon.co.uk/uploadProfilePicture.php',
    method: 'POST'
  };
  countries$: Observable<any>;
  fileError: string;
  courseRunBookings$: Observable<CourseRunBookingWithType[]>;
  salesCategories$: Observable<SalesCategory[]>;
  giftVouchers$: Observable<GiftVoucher[]>;
  loadingBookings: boolean;

  constructor(private apiSvc: ApiService,
              private toastySvc: ToastrService,
              private modalSvc: BsModalService,
              private companySvc: SystemCompanyService) {
  }

  private _contact: Contact;

  get contact(): Contact {
    return this._contact;
  }

  @Input()
  set contact(contact) {
    if (contact) {

      this._contact = contact;
      if (contact.id) {


        this.courseRunBookings$ = this.salesCategorySelector.valueChanges.pipe(
          tap(() => {
            this.loadingBookings = true;
          }),
          startWith(<string>null),
          switchMap(salesCategoryId => {
            //debugger;
            const params = {
              'no-page': true,
              jsonQuery: {
                'customer.id': this.contact.id
              },
              order: {id: 'desc'}
            };


            const clientIsParticipantParams = {
              'no-page': true,
              jsonQuery: {
                'participants.contact.id': this.contact.id,
                'customer.id': {'!$eq': this.contact.id}
              },
            };
            if (salesCategoryId && salesCategoryId != 'null') {
              params.jsonQuery['courseRun.course.salesCategory.id'] = salesCategoryId;
              clientIsParticipantParams.jsonQuery['courseRun.course.salesCategory.id'] = salesCategoryId
            }

            return forkJoin(
              [
                this.apiSvc.getBookings(params).pipe(
                  map(collection => collection.data),
                  map(courseRunBookings => courseRunBookings.map(courseRunBooking => {
                    const test = new CourseRunBookingWithType();
                    const customerParticipant = courseRunBooking.participants.find(x => x.contact.id == this.contact.id);
                    if (customerParticipant) {
                      Object.assign(test, courseRunBooking, {customerParticipant: customerParticipant});
                    }
                    return Object.assign(test, courseRunBooking, {type: 'Customer'});
                  }))
                ),
                this.apiSvc.getBookings(clientIsParticipantParams).pipe(
                  map(collection => collection.data),
                  map(courseRunBookings => courseRunBookings.map(courseRunBooking => {
                    const test = new CourseRunBookingWithType();
                    const customerParticipant = courseRunBooking.participants.find(x => x.contact.id == this.contact.id);
                    if (customerParticipant) {
                      Object.assign(test, courseRunBooking, {customerParticipant: customerParticipant});
                    }

                    return Object.assign(test, courseRunBooking, {type: 'Participant'});
                  })),
                )
              ]
            );
          }),
          map(([bookingsOwned, bookingsAsParticipant]) => {
            return [...bookingsOwned, ...bookingsAsParticipant]
          }),
          switchMap(courseRunBookings => {
              if (!courseRunBookings.length) {
                return of([]);
              }
              return forkJoin(courseRunBookings.map(courseRunBooking => {
                  return this.apiSvc.getOrder(courseRunBooking.order.id).pipe(
                    map(order => {
                      courseRunBooking.order = order;
                      return courseRunBooking;
                    }),
                  )
                })
              )
            }
          ),
          tap(() => {
            setTimeout(() => {
              this.loadingBookings = false;
            }, 100);
          })
        );


        this.memberships$ =
          this.apiSvc.getMemberships({jsonQuery: {'customer.id': contact.id}, 'no-page': true, order: {id: 'desc'}}).pipe(
            map(x => x.data)
          );

        const boughtGiftVouchersParams = {
          'no-page': true,
          jsonQuery: {
            'requestedBy.id': this.contact.id,
            'company.id': this.contact.company.id
          },
          order: {id: 'd'}
        };

        const assignedGiftVouchersParams = {
          'no-page': true,
          jsonQuery: {
            'assignedTo.id': this.contact.id,
            'company.id': this.contact.company.id
          }
        };

        this.giftVouchers$ = forkJoin(
          this.apiSvc.getGiftVouchers(boughtGiftVouchersParams),
          this.apiSvc.getGiftVouchers(assignedGiftVouchersParams)
        ).pipe(
          map(([boughtGiftVouchers, assignedGiftVouchers]) => {
            return [
              ...boughtGiftVouchers.data,
              ...assignedGiftVouchers.data
            ]
          })
        )


      }
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  test() {
    console.log(this.contact);
  }

  ngOnInit() {
    this.countries$ = this.apiSvc.getCountries();
    this.salesCategories$ = this.companySvc.getCompany().pipe(
      switchMap(company => {
        return this.apiSvc.getSalesCategories({'no-page': true, jsonQuery: {'company.id': company.id}})
      }),
      map(collection => collection.data)
    );
  }

  onSubmit() {
    this.changesSaved.next(this.contactForm.value);
  }


  pdfUploaded($event: any) {
    console.log($event);
    console.log($event.file.response);
    if ($event.type === 'done') {
      if ($event.file.response.error) {
        this.fileError = $event.file.response.error;
        return;
      }
      this.contact.profilePicture = $event.file.response.path;
    }
  }

  resendConfirmationEmail(courseRunBooking: CourseRunBooking) {
    this.apiSvc.resendConfirmationEmail(courseRunBooking).pipe(
      first()
    ).subscribe(() => {
      this.toastySvc.success('Sent');
    })
  }

  matchDates(startDate, endDate) {
    return moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
  }

  timeZone(date, format, tz) {
    return moment(date).tz(tz).format(format);
  }

  changeParticipantStatus($event, participant: BookingParticipant) {
    const modal = this.modalSvc.show(ParticipantStatusModalComponent);
    const component = <ParticipantStatusModalComponent>modal.content;
    component.participant = participant;

    this.modalSvc.onHidden.pipe(
      take(1),
      switchMap(dismissReason => {
        if (dismissReason === ParticipantStatusModalComponent.ACCEPT_REASON) {

          participant.status = $event;
          participant.notes = component.notes;

          return this.apiSvc.updateBookingParticipant(participant).pipe(
            map(success => {
              this.toastySvc.success('Updated status');
              return true;
            }),
            catchError(error => {
              this.toastySvc.error('Failed to update status');
              return of(false);
            })
          );
        }
        return of(false);
      })
    ).subscribe(success => {
      if (!success) {
        const currentStatus = participant.status;
        participant.status = null;
        setTimeout(() => {
          participant.status = currentStatus;
        })
      }
    });
  }

  resendVoucher(id: any) {
    this.apiSvc.resendGiftVoucher(id).subscribe(
      success => {
        this.toastySvc.success('Sent!');
      },
      error => {
        let errorMessage = 'Failed to send gift voucher: Server Error';

        if (error.error && error.error.description) {
          errorMessage = `${errorMessage}, ${error.error.description}`;
        }
        this.toastySvc.error(errorMessage);
      }
    )
  }
}

export class CourseRunBookingWithType extends CourseRunBooking {
  type: 'Customer' | 'Participant';
  customerParticipant?: BookingParticipant
}
