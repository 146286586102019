<div class="full-container">

  <form #form="ngForm">
    <input type="hidden" name="company" [ngModel]="giftVoucher.company">
    <input type="hidden" name="requestedBy" [ngModel]="giftVoucher.requestedBy">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <h2 class="title"><span>1. </span>Choose a gift voucher</h2>
      </div>


    </div>
    <hr>

    <app-image-picker class="row" [images]="voucherImages| async" [(ngModel)]="giftVoucher.image" name="image" required>
    </app-image-picker>

    <div class="row">
      <div class="col-md-12 col-lg-12">
        <h2 class="title"><span>2. </span>Choose the amount to send</h2>
      </div>
    </div>
    <hr>


    <div class="row">
      <div class="col-lg-12 contact-form">
        <input type="hidden" name="amount" #amount [ngModel]="giftVoucher.amount">
        <div class="row form-group" *ngIf="giftVoucher.company">
          <div class="col-lg-3 amount">
            <input type="radio" name="amountSelector" value="25" ngModel (ngModelChange)="changeAmount( 25)"
                   id="25">
            <label for="25">{{25 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
          </div>
          <div class="col-lg-3 amount">
            <input type="radio" name="amountSelector" value="50" ngModel (ngModelChange)="changeAmount(50)"
                   id="50">
            <label for="50">{{50 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
          </div>
          <div class="col-lg-3 amount">
            <input type="radio" name="amountSelector" value="100" ngModel (ngModelChange)="changeAmount( 100)"
                   id="100">
            <label for="100">{{100 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
          </div>
          <div class="col-lg-3 amount">
            <input type="radio" name="amountSelector" #custom ngModel required value="custom" id="customValue">
            <label for="customValue">Other amount</label>
          </div>
          <div class="col-lg-3 custom-amount " *ngIf="custom.checked">
            <label for="assignedTo">
              Custom Amount
            </label>

            <input autofocus
                   focus-me="focusInput"
                   type="number"
                   min="25"
                   placeholder="Choose any amount over £25"
                   name="customAmount"
                   ngModel
                   required
                   (ngModelChange)="changeAmount($event)"
                   class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-12">
            <h2 class="title"><span>3. </span>Choose who you want to send it to</h2>
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-lg-6 form-group" *ngIf="contacts.length">
            <label for="assignedTo">Choose from your contact list:</label>
            <select name="assignedTo"
                    id="assignedTo" [(ngModel)]="giftVoucher.assignedTo" [compareWith]="compareContact('id')"
                    class="form-control" (ngModelChange)="changeAssignedTo()">
              <option [ngValue]="null">-- No Contact --</option>
              <option *ngFor="let contact of contacts" [ngValue]="contact">
                {{contact.firstName + ' ' + contact.lastName}}
              </option>
            </select>
          </div>

        </div>


        <div class="row">


          <div class="col-lg-6 form-group">
            <label for="firstName">
              Recipients First Name <span style="color: red">*</span>
            </label>
            <input class="form-control" type="text" id="firstName" name="firstName"
                   placeholder="Enter first name" required [readonly]="giftVoucher.assignedTo" #firstName="ngModel"
                   ngModel>
            <small class="text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">First name is
              required
            </small>

          </div>
          <div class="col-lg-6 form-group">
            <label for="lastName">
              Recipients Last Name <span style="color: red">*</span>
            </label>
            <input type="text" id="lastName" name="lastName"
                   placeholder="Enter last name" required
                   class="form-control" #lastName="ngModel" ngModel [readonly]="giftVoucher.assignedTo">

            <small class="text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">Last name is
              required
            </small>


          </div>
          <div class="col-lg-6 form-group">
            <label for="email">
              Recipients Email <span style="color: red">*</span>
            </label>
            <input type="email" id="email" name="emailTo" placeholder="Enter email" required
                   class="form-control" ngModel>
            <p style="color: #969696; font-size: 0.8rem; margin-top: 1rem;">If you don't know the email of the recipient
              use please enter your own, and the voucher will be sent to you.</p>
          </div>

          <div class="col-lg-6 form-group">
            <label for="message" class="optional">
              Message <span style="color: #969696; font-size: 0.8rem;">(Optional)</span>
            </label>
            <textarea id="message" name="message" class="form-control" ngModel>
                                </textarea>
          </div>
          <div class="col-lg-6 form-group">
            <label>Select Date <span style="color: red">*</span> </label>
            <input type="datePicker" [matDatepicker]="dateToSendDatePicker" id="dateToSend" name="dateToSend"
                   [(ngModel)]="giftVoucher.dateToSend" [matDatepickerFilter]="futureDates" [min]="today" required>
            <mat-datepicker-toggle [for]="dateToSendDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateToSendDatePicker></mat-datepicker>
          </div>

          <div class="col-lg-6 form-group">
            <label for="timeToSend">
              Select Time <span style="color: red">*</span>
            </label>
            <select name="timeToSend" id="timeToSend" required ngModel class="form-control">
              <option [ngValue]="null">-- Choose a time --</option>
              <option *ngFor="let option of timeToSendOptions" [value]="option.realTime">{{option.stupidTime}}</option>
            </select>
          </div>

        </div>

        <div class="form-group">
          <button class="btn btn-success" (click)="submit()" [disabled]="!form.valid">
            Add to Cart
          </button>
        </div>

      </div>

    </div>
  </form>
</div>


<!-- <form #form="ngForm">
  <input type="hidden" name="company" [ngModel]="giftVoucher.company">
  <input type="hidden" name="requestedBy" [ngModel]="giftVoucher.requestedBy">

  <label for="assignedTo">Send To:</label>
  <div>
    <app-image-picker [images]="voucherImages| async" [(ngModel)]="giftVoucher.image" name="image" required>
    </app-image-picker>
  </div>
  <div *ngIf="contacts.length" class="form-group">
    <label for="assignedTo">
      Pick a contact
    </label>
    <select name="assignedTo" id="assignedTo" [(ngModel)]="giftVoucher.assignedTo"
      [compareWith]="compareContact('id')" class="form-control">
      <option [ngValue]="null">-- No Contact --</option>
      <option *ngFor="let contact of contacts" [ngValue]="contact">
        {{contact.firstName + ' ' + contact.lastName}}
      </option>
    </select>
  </div>
  <br>
  <div> Or just add the info
    <div class="form-group">
      <label for="firstName">
        First Name
      </label>
      <input class="form-control" type="text" id="firstName" name="firstName" [required]="!giftVoucher.assignedTo"
             ngModel>
    </div>
    <div class="form-group">
      <label for="lastName">
        Last Name
      </label>
      <input type="text" id="lastName" name="lastName" [required]="!giftVoucher.assignedTo" class="form-control"
             ngModel>
    </div>
    <div class="form-group">
      <label for="email">
        Email
      </label>
      <input type="email" id="email" name="email" [required]="!giftVoucher.assignedTo" class="form-control"
             ngModel>
    </div>
    <input type="hidden" name="type" value="friend">
  </div>

  <div class="form-group">
    <label for="message">
      Message:
    </label>
    <textarea id="message" name="message" class="form-control"
              ngModel>
      </textarea>
  </div>
  <div class="form-group">
    <label for="dateToSend">Date: </label>
    <div class="form-control">
      <input [matDatepicker]="dateToSendDatePicker" id="dateToSend" name="dateToSend"
        [(ngModel)]="giftVoucher.dateToSend"
        [matDatepickerFilter]="futureDates"
        [min]="today"
                                                    required>
      <mat-datepicker-toggle [for]="dateToSendDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dateToSendDatePicker></mat-datepicker>
    </div>
  </div>
  <div>
    <label for="timeToSend">
      Time
    </label>
    <select name="timeToSend" id="timeToSend" required ngModel class="form-control">
      <option *ngFor="let option of timeToSendOptions" [value]="option.realTime">{{option.stupidTime}}</option>
    </select>
  </div>
  <div class="form-group" *ngIf="giftVoucher.company">
    <label>Amount:</label>
    <div class="container">
      <div>
        <input type="radio" name="amount" value="25" ngModel (ngModelChange)="changeAmount(25)"
               id="25">
        <label for="25">{{25 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
      </div>
      <div>
        <input type="radio" name="amount" value="50" ngModel (ngModelChange)="changeAmount(50)"
               id="50">
        <label for="50">{{50 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
      </div>
      <div>
        <input type="radio" name="amount" value="100" ngModel (ngModelChange)="changeAmount(100)"
               id="100">
        <label for="100">{{100 | currency:giftVoucher.company.currency.code:'symbol'}}</label>
      </div>
      <div>
        <input type="radio" name="amount" #custom ngModel required value="custom" id="customValue">
        <label for="customValue">Enter any amount of your choosing</label>
      </div>
    </div>
    <input type="number" min="25" *ngIf="custom.checked" name="customAmount" ngModel required
      (ngModelChange)="changeAmount($event)" class="form-control">
    <div *ngIf="form.value.customAmount && form.value.customAmount < 25">
      Value must be above 25
    </div>
  </div>

  <button class="btn btn-success" (click)="submit()" [disabled]="!form.valid">
    Add to Cart
  </button>
</form>
 -->
