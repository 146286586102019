import {Company} from './Company';
import {MembershipBookingLimitation} from './MembershipBookingLimitation';
import {PhysicalLocation} from './Location';
import {Course} from './Course';
import {Serializable} from './Serializable';

export class MembershipType implements Serializable<MembershipType> {
  id: string | number;
  duration: number;
  location: PhysicalLocation = null;
  company: Company;
  bookGuestYear: number;
  limitations: MembershipBookingLimitation[];
  type: string;

  constructor() {
    this.company = new Company();
    this.limitations = [];
  }


  getCourseDiscount(course: Course) {
    return 0;
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.duration = input.duration;
    if (input.location) {
      this.location = new PhysicalLocation().deserialize(input.location);
    }
    this.company = new Company().deserialize(input.company);
    this.bookGuestYear = input.bookGuestYear;
    this.limitations = [];
    if (input.limitations) {

      this.limitations = input.limitations.map(limitation => {
        const newLimitation = new MembershipBookingLimitation().deserialize(limitation);
        newLimitation.membershipType = this;
        return newLimitation;
      });
    }
    this.type = input.type;

    return this;
  }
}


