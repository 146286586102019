import {Component} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {BookingParticipant} from '../../../../models/BookingParticipant';
import {FormControl} from '@angular/forms';


@Component({
  templateUrl: './participant-status-modal.component.html',
  styleUrls: ['./participant-status-modal.component.scss']
})
export class ParticipantStatusModalComponent {

  static ACCEPT_REASON = 'accept';

  private _participant: BookingParticipant;

  set participant(participant: BookingParticipant) {
    this._participant = participant;
    this.notesControl.patchValue(participant.notes);
  }

  notesControl: FormControl;
  notes: string;

  constructor(public bsModalRef: BsModalRef, private modalSvc: BsModalService) {
    this.notesControl = new FormControl(null);
  }

  saveChanges() {
    this.notes = this.notesControl.value;
    this.modalSvc.setDismissReason(ParticipantStatusModalComponent.ACCEPT_REASON);
    this.bsModalRef.hide();
  }
}
