import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentTimezone',
    pure: true
  }
)
export class MomentTimezonePipe implements PipeTransform {
  transform(value: string, timezone: string): any {
    return moment.utc(value).tz(timezone);
  }

}




