import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Contact} from '../../../models/Contact';
import {HttpClient} from '@angular/common/http';
import {TokenInterceptor} from '../../_interceptors/token.interceptor';
import * as Sentry from '@sentry/browser';
import {ConnectableObservable, Observable, of} from 'rxjs';
import {catchError, map, publishReplay} from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
  baseUrl: string;
  meData: Contact;
  meObs$: ConnectableObservable<Contact>;

  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = environment.apiUrl;
    this.meData = new Contact();
  }

  login(email: string, password: string) {
    ////console.log('executing login');

    return this.http.post<{ token: string }>(this.baseUrl + '/login', {email, password}).pipe(
      map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const jwtHelper: JwtHelperService = new JwtHelperService();
        const decodedToken = jwtHelper.decodeToken(data.token);
        this.meObs$ = undefined;
        // if (!decodedToken || !decodedToken.user || !decodedToken.user.staffMember) {
        //   throw 'You are not a Staff Member';
        // }
        localStorage.setItem('currentUser', JSON.stringify(decodedToken));

        TokenInterceptor.setToken(data.token);
        return data;
      })
    )

  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('profileData');
    TokenInterceptor.clearToken();
  }

  isLoggedIn() {
    return !!TokenInterceptor.getToken();
  }

  checkUser() {
    return this.getProfileFromServer().pipe(
      map(profile => {
        if (!profile || !profile.staffMember) {
          throw new Error('You are not allowed if you are not a staff member');
        }
      })
    );
  }

  getProfileData(): Observable<Contact> {
    //this.getProfileFromServerAsync();
    return this.getProfileFromServer();
  }

  private getProfileFromServer() {

    // return this.http.get<any>(`${this.baseUrl}/me`).pipe(
    //   map(profile => {
    //     Sentry.setUser(profile);
    //     localStorage.setItem('profileData', JSON.stringify(profile));
    //     return profile
    //   }),
    //   catchError((error) => {
    //     console.error('Failed to retrieve profile', error);
    //     this.router.navigate(['login']);
    //     return of(new Contact());
    //   }),
    //   shareReplay(1)
    // )

    ////console.log(this.meObs$);
    if (this.meObs$ != undefined)
      return this.meObs$;
    this.meObs$ = this.http.get<any>(`${this.baseUrl}/me`)
      .pipe(
        map(profile => {
          Sentry.setUser(profile);
          localStorage.setItem('profileData', JSON.stringify(profile));
          return profile;
        }),
        catchError((error) => {
          //console.log('getprofileerror', error);
          this.router.navigate(['/login']);
          return of(new Contact());
        }),
        publishReplay()
      ) as ConnectableObservable<Contact>;
    this.meObs$.connect();
    return this.meObs$;
  }

  resetPassword(token: string, newPassword: string) {
    const data = {
      token: token,
      newPassword: newPassword
    };
    return this.http.post<{ status: boolean }>(this.baseUrl + '/resetPassword', data);
  }

  requestResetPasswordEmail(email: string) {
    const data = {
      email: email
    };
    return this.http.post(this.baseUrl + '/requestPasswordReset', data)
  }
}
