import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ApiService} from 'app/_services/api.service';
import {debounceTime, finalize, switchMap, takeUntil, tap} from 'rxjs/operators';
import {isObject} from 'util';
import {of, Subject} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'customer-companies-selector',
  templateUrl: './customer-companies-selector.component.html',
  styleUrls: ['./customer-companies-selector.component.scss']
})
export class CustomerCompaniesSelectorComponent implements OnInit {
  @ViewChild('modalCompany', {static: true}) modalCompany;
  @Output() selectedCompany: EventEmitter<any> = new EventEmitter();
  @Input() fullLoad = false;
  @Input() isPartner = false;

  @Input('customerCompany')
  set customerCompany(val) {
    this.selectedCompanyControl.patchValue(val == null ? '' : val);
  }

  isLoading = false;
  filteredCompanies = [];
  selectedCompanyControl: FormControl;
  placeholder = 'Choose a Company';
  modalRef: BsModalRef;
  onDestroy$: Subject<boolean> = new Subject();

  constructor(private apiSvc: ApiService, private modalService: BsModalService,
  ) {
    this.selectedCompanyControl = new FormControl();
  }

  ngOnInit() {
    this.searchCompany();
  }

  displayFn(company) {
    if (company) {
      return company.name;
    }
  }

  searchCompany() {
    let params: any;
    params = {
      jsonQuery: {
        $and: {name: {$contains: ''}},
      },
      'no-page': true,
      order: 'name',
      groups: ['customerCompanySummary'],
    };

    if (this.isPartner) {
      params.jsonQuery.$and = {...params.jsonQuery.$and, isPartner: 1};
    } else {
      params.jsonQuery.$or = {isPartner: {$isNull: ''}, $and: {isPartner: {$eq: 0}}};

    }

    this.selectedCompanyControl.valueChanges
      .pipe(
        debounceTime(500),
        tap((value) => {
          this.placeholder = 'Choose a Company';
          this.isLoading = true;
          params.jsonQuery.$and.name.$contains = value;
        })
        , switchMap((value) => {
            if (value != null && value != '' && !isObject(params.jsonQuery.$and.name.$contains) && params.jsonQuery.$and.name.$contains != '')
              return this.apiSvc.getCustomerCompanies(params).pipe(
                finalize(() => this.isLoading = false))
            else return of([]);
          }
        ), takeUntil(this.onDestroy$)
      ).subscribe((response: any) => {
      // console.log(response);
      if (response != null && response.data != undefined)
        this.filteredCompanies = response.data;
      this.isLoading = false;
    })
  }

  newCustomerCompany() {
    this.modalRef = this.modalService.show(this.modalCompany);
    this.modalService.onHide.subscribe((val) => {
      //console.log('modal closed',val);
    });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.selectedCompany) {
      this.emitCompanyEvent(event.option.value);
    }
  }

  emitCompanyEvent(company) {
    if (this.fullLoad)
      this.apiSvc.getCustomerCompany(company.id).pipe(takeUntil(this.onDestroy$))
        .subscribe(company => this.selectedCompany.emit(company))
    else
      this.selectedCompany.emit(company);
  }

  companyAdded(event) {
    //console.log('new company =>',event);
    this.emitCompanyEvent(event);
    this.modalService.hide(1);
    this.selectedCompanyControl.setValue({name: event.name});

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
  }
}
