<div class="modal-header">
  <h4 class="modal-title pull-left">Add Client </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="giftVoucher">
  <pavweb-voucher [giftVoucher]="giftVoucher" [contacts]="contacts" (onSubmit)="submitForm($event)"></pavweb-voucher>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Close</button>
</div>

