import {Company} from './Company';
import {UUID} from '../app/utils/Utils';
import {Course} from './Course';
import {Serializable} from './Serializable';

export enum DiscountType {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount'
}

export class DiscountCode implements Serializable<DiscountCode> {
  id: string | number;
  code: string;
  type: DiscountType;
  amount: string;
  courses: Course[];
  company: Company;
  expiryDate: string;

  constructor() {
    this.id = UUID();
    this.courses = [];
    this.company = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.code = input.code;
    this.type = input.type;
    this.amount = input.amount;
    if (input.courses) {
      this.courses = input.courses.map(item => {
        return new Course().deserialize(item);
      });
    }
    this.company = new Company().deserialize(input.company);
    this.expiryDate = input.expiryDate;
  }
}
