import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {ApiService} from '../_services/api.service';
import {ContactCollection} from '../../models/Collections/ContactCollection';
import {EmailValidator} from '../theme/validators';
import {map, take} from 'rxjs/operators';

@Directive({
  selector: '[validateUniqueEmail][formControlName], [validateUniqueEmail][formControl], [validateUniqueEmail][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => UniqueEmailValidatorDirective), multi: true
    }
  ]
})
export class UniqueEmailValidatorDirective implements Validator {

  @Input('validateUniqueEmail') contactId: number | string;

  constructor(private apiSvc: ApiService) {

  }

  validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {

    const v = c.value;

    // we need to check if there's a contactId given to us, if it's given then we need to exclude that guy from the search

    // we don't want to contact the server with emails that are not valid..so we filter them

    if (!EmailValidator.validateEmail(c.value)) {
      return of(null).pipe(take(1));
    }

    let params: any = {
      jsonQuery: {email: v}
    };

    if (this.contactId > 0) {
      params.jsonQuery = {
        ...params.jsonQuery,
        id: {'!$eq': this.contactId}
      };
    }

    return this.apiSvc.getContacts(params).pipe(
      map((contactCollection: ContactCollection) => {
        if (contactCollection.data.length > 0) {
          return {nonUniqueEmail: true}
        }
        return null;
      })
    )
  }


}
