import {ResourceType} from './ResourceType';
import {UUID} from '../app/utils/Utils';
import {PhysicalLocation} from './Location';
import {Company} from './Company';
import {Serializable} from './Serializable';

export class Resource implements Serializable<Resource> {
  id: number | string;
  type: ResourceType;
  location: PhysicalLocation;
  name: string;
  enabled: boolean = true;
  company: Company;

  constructor() {
    this.id = UUID();
    this.type = new ResourceType();
    this.location = new PhysicalLocation();
    this.company = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.type = new ResourceType().deserialize(input.type);
    this.location = new PhysicalLocation().deserialize(input.location);
    this.name = input.name;
    this.enabled = input.enabled;
    this.company = new Company().deserialize(input.company);
    this.type.company = this.company;
    return this;
  }
}

