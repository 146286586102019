<div class="page-top clearfix" baScrollPosition [maxHeight]="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
  <a routerLink="/pages/dashboard" class="al-logo clearfix">Booking<span _ngcontent-c2="">System</span></a>
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-md-menu">
  </a>

  <div class="search">
    <i class="ion-ios-search-strong"></i>
    <input id="searchInput" type="text" placeholder="Search for...">
  </div>
  <div class="user-profile clearfix">
    <select *ngIf="isSuperAdmin" id="BusinessCheck" [ngModel]="selectedCompany" (ngModelChange)="changedCompany($event)"
            [compareWith]="compareWithId">
      <option *ngFor="let company of companies" [ngValue]="company">
        {{company.name}}
      </option>
    </select>
    <div dropdown class="al-user-profile" container="body">
      <a class="profile-toggle-link" dropdownToggle id="user-profile-dd" data-toggle="dropdown" aria-expanded="false">
        {{firstName$ | async}}
      </a>
      <ul *dropdownMenu class="top-dropdown-menu profile-dropdown dropdown-menu" aria-labelledby="user-profile-dd">
        <li class="dropdown-item"><i class="dropdown-arr"></i></li>
        <li class="dropdown-item"><a routerLink="/pages/profile"><i class="fa fa-user"></i>Profile</a></li>
        <li class="dropdown-item"><a href><i class="fa fa-cog"></i>Settings</a></li>
        <li class="dropdown-item"><a (click)="signOut()" class="signout" style="cursor: pointer;"><i
          class="fa fa-power-off"></i>Sign out</a></li>
      </ul>
    </div>
  </div>
</div>
