import { Injectable } from "@angular/core";
@Injectable()
export class CacheService {
  getItem(key: string): any {
    const found = localStorage.getItem(key);

    if (!found) {
      return null;
    }

    return JSON.parse(found);
  }

  saveItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
