import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {GeneratorDataItem} from '../../../../models/GeneratorDataItem';
import {ResourceCalendar} from '../resourceCalendar/ResourceCalendar';

@Component({
  selector: 'nga-course-week-session-picker',
  templateUrl: './course-week-session-picker.html',
})
export class CourseWeekSessionPicker {

  tabs = [];
  _generatorDataItems: GeneratorDataItem[];
  _selectedTab: number = 1;

  @ViewChild('courseWeek', {static: true}) courseWeekTemplate;

  @Input('calendarColor')
  set calendarColor(value) {
    this.calendarOptions.eventColor = value;
    if (this.calendar) {
      this.calendar.fullCalendar('option', 'eventColor', value);
      this.calendar.fullCalendar('refetchEvents');
    }

  }

  @Input('generatorDataItems')
  set generatorDataItems(value) {
    const seenWeeks = {};
    this.tabs.map((item, index) => {
      seenWeeks[index + 1] = true;
    });

    this._generatorDataItems = value;

    const highestWeekIdx = this._generatorDataItems.reduce((acc, item) => {
      if (item.weekIdx > acc) {
        return item.weekIdx
      }
      return acc;
    }, 0);

    for (let i = 1; i <= highestWeekIdx; i++) {
      if (!seenWeeks[i]) {
        this.addWeek();
      }
    }

    //check that we at least have one week to show
    if (this.tabs.length == 0) {
      this.addWeek();
    }

    this.selectTab(this._selectedTab);

  }

  @Input()
  set timezone(timezone) {
    this.calendarOptions.timezone = timezone;
    if (this.calendar) {
      this.calendar.fullCalendar('option', 'timezone', timezone);
      this.calendar.fullCalendar('refetchEvents');
    }
  }

  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();
  @Output() eventModified: EventEmitter<{ oldEvent: any, newEvent: any }> = new EventEmitter<{ oldEvent: any, newEvent: any }>();
  @Output() eventCreated: EventEmitter<any> = new EventEmitter();
  @Output() weekDeleted: EventEmitter<any> = new EventEmitter();

  calendarOptions;
  calendar: ResourceCalendar;


  constructor() {
    this.calendarOptions = {
      height: '500px',
      slotDuration: '00:15:00',
      scrollTime: '08:30:00',
      defaultView: 'agendaWeek',
      defaultDate: moment().day(-6).toDate(),
      columnFormat: 'dddd',
      eventClick: this.eventClick.bind(this),
      firstDay: 1,
      header: false,
      allDaySlot: false,
      editable: true,
      eventLimit: true, // allow "more" link when too many events
      selectable: true,
      selectHelper: true,
      overlap: false,
      eventOverlap: false,
      selectOverlap: false,
      select: this.eventSelect.bind(this),
      events: this.fetchEventsForWeek.bind(this),
      eventDrop: this.eventModify.bind(this),
      eventResize: this.eventModify.bind(this),
    };
  }


  addWeek() {
    this.tabs = this.tabs.map((tab) => {
      tab.removable = false;
      return tab;
    });

    const weekIdx = this.tabs.length + 1;

    this.tabs.push({
      active: false,
      title: `Week ${weekIdx}`,
      removable: weekIdx > 1,
      weekIdx,
    })

  }

  removeTabHandler() {
    if (this.tabs.length > 1) {
      const lastTab = this.tabs[this.tabs.length - 1];
      this.tabs.pop();
      if (this.tabs.length > 1) {
        const lastTab = this.tabs[this.tabs.length - 1];
        lastTab.removable = true;
      }

      if (this._selectedTab === lastTab.weekIdx) {
        this._selectedTab--;
      }

      this.weekDeleted.next(lastTab.weekIdx);
    }
  }


  eventSelect(start, end, allDay) {
    const weekIdx = this._selectedTab;
    const newEvent = {
      id: 1 - parseInt(moment().format('x')),
      duration: end.diff(start, 'minutes'),
      startTime: start.format('HH:mm'),
      weekday: start.isoWeekday(),
      weekIdx: weekIdx
    };

    if (this.calendar) {
      this.calendar.fullCalendar('unselect');
    }

    this.eventCreated.next(newEvent);
  }


  fetchEventsForWeek(startDate, endDate, tz, callback) {
    const weekIdx = this._selectedTab;
    if (!this._generatorDataItems) {
      callback([]);
      return;
    }
    const internalEvents = this._generatorDataItems.filter(
      (eventItem) => eventItem.weekIdx == weekIdx).map((eventItem) => {
      const startMoment = moment(eventItem.startTime, 'HH:mm').day(eventItem.weekday - 7);
      const endMoment = startMoment.clone().add(eventItem.duration, 'minutes');
      return {
        title: `week ${weekIdx}`,
        start: startMoment.format(),
        end: endMoment.format(),
        id: eventItem.id,
      }
    });

    callback(internalEvents);

  }


  selectTab(weekIdx) {
    this.tabs.map(tab => {
      tab.active = tab.weekIdx == weekIdx;
      if (tab.active) {
        this._selectedTab = tab;
      }
    });
    this._selectedTab = weekIdx;
    if (this.calendar) {
      this.calendar.fullCalendar('refetchEvents');
    }
  }

  eventModify(event, delta, revertFunc, jsEvent, ui, view) {

    const modifiedEvent = {
      startTime: event.start.format('HH:mm'),
      duration: event.end.diff(event.start, 'minutes'),
      id: 1 - parseInt(moment().format('x')),
      weekday: event.start.isoWeekday(),
      weekIdx: this._selectedTab,
    };

    this.eventModified.next({oldEvent: event, newEvent: modifiedEvent});

  }

  eventClick(event) {
    debugger;
    this.eventDeleted.next(event);
  }


  calendarInit($event: ResourceCalendar) {
    this.calendar = $event;
  }
}
