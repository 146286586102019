import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {map, startWith, tap} from 'rxjs/operators';
import {Contact} from '../../models/Contact';
import * as Sentry from '@sentry/browser'
import {JwtHelperService} from '@auth0/angular-jwt';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  private static _tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  public static decodedToken$: Observable<Contact> = TokenInterceptor._tokenSubject.pipe(
    startWith(localStorage.getItem('token')),
    map(token => {
      if (!token) {
        return null;
      }

      const helper = new JwtHelperService();
      return helper.decodeToken(token).user;
    })
  );

  constructor(public router: Router) {
  }

  static setToken(token) {
    localStorage.setItem('token', token);
    TokenInterceptor._tokenSubject.next(token);
  }

  static clearToken() {
    localStorage.removeItem('token');
    TokenInterceptor._tokenSubject.next(null);
  }

  static getToken(): string {
    return localStorage.getItem('token');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check if we actaully have a token

    const token = localStorage.getItem('token');

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with the response ..if necessary
        }
      }, (err: any) => {

        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            TokenInterceptor.clearToken();
            this.router.navigate(['/login']);
          }

          if (err.status === 500) {
            Sentry.captureException(JSON.stringify(err));
          }
        }
      })
    );
  }


}
