import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'pavweb-resource-calendar',
  templateUrl: './ResourceCalendar.html'
})
export class ResourceCalendar implements AfterViewInit {

  defaultOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    scrollTime: '08:00:00'
  };

  @Input() options;
  @Output() initialized: EventEmitter<ResourceCalendar> = new EventEmitter<ResourceCalendar>();

  constructor(private elmRef: ElementRef) {

  }

  ngAfterViewInit(): void {

    this.options = {
      ...this.defaultOptions,
      ...this.options,
    };

    const calendar = jQuery('pavweb-resource-calendar').fullCalendar(this.options);
    this.initialized.emit(this);
  }

  public fullCalendar(...args: any[]) {
    if ( !args ) {
      return;
    }
    switch (args.length) {
      case 0:
        return;
      case 1:
        return $(this.elmRef.nativeElement).fullCalendar(args[0]);
      case 2:
        return $(this.elmRef.nativeElement).fullCalendar(args[0], args[1]);
      case 3:
        return $(this.elmRef.nativeElement).fullCalendar(args[0], args[1], args[2]);
    }
  }

}
