<div class="row justify-content-center" [class.clear-theme]="isModal">
  <div class="col-xs-12 col-md-12">
    <div *ngIf="contact">
      <form #contactForm="ngForm" (ngSubmit)="onSubmit()">
        <input type="hidden" name="id" [(ngModel)]="contact.id">
        <input type="hidden" name="company" [ngModel]="contact.company">
        <div class="form-group">Enabled?:&nbsp;&nbsp;&nbsp;&nbsp;
          <input type="checkbox" style="transform: scale(2);" id="enabled" name="enabled"
            [(ngModel)]="contact.enabled">
        </div>
        <div class="form-group" style="margin-top: 30px;">
          <label for="email">Email</label>
          <input class="form-control" id="email" name="email" [validateUniqueEmail]="contact.id"
            [(ngModel)]="contact.email" [required]="isClient" #emailInput="ngModel" email>
          <div *ngIf="emailInput.hasError('nonUniqueEmail')">
            The email already exists
          </div>
        </div>
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input class="form-control" id="firstName" name="firstName" [(ngModel)]="contact.firstName" required>
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input class="form-control" id="lastName" name="lastName" [(ngModel)]="contact.lastName" required>
        </div>
        <div class="form-group">
          <label for="userName">User Name</label>
          <input class="form-control" id="userName" name="userName" [validateUniqueUsername]="contact.id"
            [(ngModel)]="contact.userName" [required]="isEdit" #usernameInput="ngModel">
          <div *ngIf="usernameInput.hasError('nonUniqueUsername')">
            The username already exists
          </div>
        </div>
        <div class="form-group">
          <label for="dob">Date of Birth</label>
          <div class="input-group">
            <input class="form-control" [matDatepicker]="dobDatePicker" id="dob" name="dob" [(ngModel)]="contact.dob">
            <div class="input-group-append">
              <mat-datepicker-toggle [for]="dobDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dobDatePicker></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="phone">Phone</label>
          <input class="form-control" id="phone" name="phone" [(ngModel)]="contact.phone">
        </div>
        <div class="form-group">
          <label for="mobile">Mobile</label>
          <input class="form-control" id="mobile" name="mobile" [(ngModel)]="contact.mobile">
        </div>
        <div class="form-group">
          <label for="emergencyContactNumber">Emergency Contact Number</label>
          <input class="form-control" id="emergencyContactNumber" name="emergencyContactNumber"
            [(ngModel)]="contact.emergencyContactNumber">
        </div>
        <div ngModelGroup="billingAddress">
          <h2>
            Address
          </h2>
          <br>
          <div class="form-group">
            <label for="addressLine1">Address Line 1</label>
            <input class="form-control" id="addressLine1" name="addressLine1"
              [(ngModel)]="contact.billingAddress.addressLine1">
          </div>
          <div class="form-group">
            <label for="addressLine2">Address Line 2</label>
            <input class="form-control" id="addressLine2" name="addressLine2"
              [(ngModel)]="contact.billingAddress.addressLine2">
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <input class="form-control" id="city" name="city" [(ngModel)]="contact.billingAddress.city">
          </div>
          <div class="form-group">
            <label for="county">County/State</label>
            <input class="form-control" id="county" name="county" [(ngModel)]="contact.billingAddress.county">
          </div>
          <div class="form-group">
            <label for="postCode">Post Code</label>
            <input class="form-control" id="postCode" name="postCode" [(ngModel)]="contact.billingAddress.postCode">
          </div>
          <div class="form-group">
            <label for="country">Country</label>
            <select class="form-control" id="country" name="country" [(ngModel)]="contact.billingAddress.country"
              [compareWith]="countryCompare" required>
              <option *ngFor="let country of (countries$|async)" [ngValue]="country">{{country.name}}
              </option>
            </select>
          </div>
        </div>
        <input type="hidden" [(ngModel)]="contact.company" name="company">
        <button class="btn btn-primary" [disabled]="contactForm.invalid">
          {{buttonValue}}
        </button>
      </form>
    </div>
  </div>
</div>