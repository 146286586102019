import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component( {
  selector: 'ba-picture-uploader',
  styleUrls: ['./baPictureUploader.scss'],
  templateUrl: './baPictureUploader.html',
} )
export class BaPictureUploader {

  @Input() defaultPicture = '';
  @Input() picture = '';

  @Input() uploaderOptions = { url: '' };
  @Input() canDelete = true;

  @Output() onUpload = new EventEmitter<any>();
  @Output() onUploadCompleted = new EventEmitter<any>();

  @ViewChild( 'fileUpload' , {static: true}) public _fileUpload: ElementRef;

  public uploadInProgress: boolean;

  constructor( private renderer: Renderer2 ) {
  }

  beforeUpload( uploadingFile ): void {
    let files = this._fileUpload.nativeElement.files;

    if ( files.length ) {
      const file = files[0];
      this._changePicture( file );

      if ( !this._canUploadOnServer() ) {
        uploadingFile.setAbort();
      } else {
        this.uploadInProgress = true;
      }
    }
  }

  bringFileSelector(): boolean {
    this._fileUpload.nativeElement.click();
    return false;
  }

  removePicture(): boolean {
    this.picture = '';
    return false;
  }

  _changePicture( file: File ): void {
    const reader = new FileReader();
    reader.addEventListener( 'load', ( event: Event ) => {
      this.picture = (<any> event.target).result;
    }, false );
    reader.readAsDataURL( file );
  }

  _onUpload( data ): void {
    if ( data['done'] || data['abort'] || data['error'] ) {
      this._onUploadCompleted( data );
    } else {
      this.onUpload.emit( data );
    }
  }

  _onUploadCompleted( data ): void {
    this.uploadInProgress = false;
    this.onUploadCompleted.emit( data );
  }

  _canUploadOnServer(): boolean {
    return !!this.uploaderOptions['url'];
  }
}
