import {GeneratorDataItem} from './GeneratorDataItem';
import {CourseSession} from './CourseSession';
import {PhysicalLocation} from './Location';
import {CourseResourceRequirement} from './CourseResourceRequirement';
import {Company} from './Company';
import {SalesCategory} from './SalesCategory';
import {CancellationPolicy} from './CancellationPolicy';
import {CoursePriceElement} from './CoursePriceElement';
import {SubCourse} from './SubCourse';
import {Serializable} from './Serializable';
import {PriceElement} from './PriceElement';
import {CourseType} from './CourseType';


export class Course implements Serializable<Course> {
  id: number;
  courseSessions: CourseSession[];
  name: string;
  price: string;
  calendarColor: string;
  notes: string;
  waitingList: boolean;
  salesCategory: SalesCategory;
  tax: boolean;
  clientCanCancel = false;
  visibility = true;
  type: CourseType;
  inHouseCertificate: string;
  cancellationPolicy: CancellationPolicy;
  preRequirements: string;
  generator: string;
  generatorDataItems: GeneratorDataItem[];
  slotDuration: number;
  location: PhysicalLocation;
  resourceRequirements: CourseResourceRequirement[];
  groupCourse: boolean;
  maxNumbers: number;
  pdfBrochureFilePath: string;
  pdfBrochureURL: string;
  billingCompany: Company;
  priceElements: CoursePriceElement[];
  enabled = true;
  subCourses: SubCourse[];
  hasSubCourses = false;

  constructor() {
    this.courseSessions = [];
    this.salesCategory = new SalesCategory();
    this.type = new CourseType();
    this.cancellationPolicy = new CancellationPolicy();
    this.generatorDataItems = [];
    this.location = new PhysicalLocation();
    this.resourceRequirements = [];
    this.billingCompany = new Company();
    this.priceElements = [];
    this.subCourses = [];
  }

  getPrice() {
    return this.priceElements.reduce(
      (prevValue, currentItem) => {
        return parseFloat(prevValue + currentItem.amount);
      }, 0
    );
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;

    this.courseSessions = [];
    if (input.courseSessions) {
      this.courseSessions = input.courseSessions.map(item => {
        return new CourseSession().deserialize(item);
      });
    }

    this.name = input.name;
    this.price = input.price;
    this.calendarColor = input.calendarColor;
    this.notes = input.notes;
    this.waitingList = input.waitingList;
    this.salesCategory = new SalesCategory().deserialize(input.salesCategory);
    this.tax = input.tax;
    this.clientCanCancel = input.clientCanCancel;
    this.visibility = input.visibility;
    this.type = new CourseType().deserialize(input.type);
    this.inHouseCertificate = input.inHouseCertificate;
    this.cancellationPolicy = new CancellationPolicy().deserialize(input.cancellationPolicy);
    this.preRequirements = input.preRequirements;
    this.generator = input.generator;
    this.generatorDataItems = [];

    if (input.generatorDataItems) {
      this.generatorDataItems = input.generatorDataItems.map(item => {
        return new GeneratorDataItem().deserialize(item);
      });
    }

    this.slotDuration = input.slotDuration;
    this.location = new PhysicalLocation().deserialize(input.location);
    this.resourceRequirements = [];

    if (input.resourceRequirements) {
      this.resourceRequirements = input.resourceRequirements.map(item => {
        const resourceRequirement = new CourseResourceRequirement().deserialize(item);
        resourceRequirement.course = this;
        return resourceRequirement.course;
      });
    }

    this.groupCourse = input.groupCourse;
    this.maxNumbers = input.maxNumbers;
    this.pdfBrochureFilePath = input.pdfBrochureFilePath;
    this.pdfBrochureURL = input.pdfBrochureURL;
    this.billingCompany = new Company().deserialize(input.billingCompany);
    this.priceElements = [];
    if (input.priceElements) {
      this.priceElements = input.priceElements.map(item => {
        const priceElement = new PriceElement().deserialize(item);
        return priceElement;
      });
    }

    this.enabled = input.enabled;
    this.subCourses = [];

    if (input.subCourses) {
      this.subCourses = input.subCourses.map(item => {
        const subCourse = new SubCourse().deserialize(item);
      });
    }
    this.hasSubCourses = input.hasSubCourses;

    return this;
  }
}

export interface CourseJSON {

  id?: number;
  courseSessions?: CourseSession[];
  name?: string;
  price?: string;
  calendarColor?: string;
  notes?: string;
  waitingList?: boolean;
  salesCategory?: SalesCategory;
  tax?: boolean;
  clientCanCancel?: boolean;
  visibility?: boolean;
  type: CourseType;
  inHouseCertificate: string;
  cancellationPolicy: string;
  preRequirements: string;
  generator: string;
  generatorDataItems: any;
  slotDuration: number;
}
