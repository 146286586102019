import {Company} from '../../models/Company';
import {Injectable} from '@angular/core';
import {distinctUntilChanged, map, shareReplay, tap} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {ApiService} from './api.service';


@Injectable()
export class SystemCompanyService {

  companyChanged = new ReplaySubject<Company>(1);
  observable;
  private companies: Observable<Company[]>;

  constructor(private apiService: ApiService) {
    this.observable = this.companyChanged.asObservable().pipe(distinctUntilChanged());
    this.companies = this.apiService.getCompanies().pipe(shareReplay(1), map(companiesCollection => companiesCollection.data));
  }

  setCompanyById(companyId) {
    return this.companies.pipe(tap(companies => {
      this.setCompany(companies.find(x => x.id = companyId));
    }))
  }

  setCompany(company: Company) {
    this.companyChanged.next(company);
  }

  getCompany(): Observable<Company> {
    return this.observable;
  }

}
