<ng-container *ngIf="images; else loadingImages">

  <div class="col-md-12 col-lg-4 gift-voucher" *ngFor="let image of images; let i = index">
    <a (click)="selectImage(i)" style="cursor: pointer;">
      <img [src]="apiURL + image.pathToImage" alt=""
           [class.imageSelected]="selectedImage == i" class="img-responsive">
    </a>
  </div>
</ng-container>
<ng-template #loadingImages>
  <div>
    Loading images...
  </div>
</ng-template>
