import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../../_services/api.service';
import {NgForm} from '@angular/forms';


import {InvoiceItem} from '../../../../../models/InvoiceItem';
import BigNumber from 'bignumber.js';
import {Company} from '../../../../../models/Company';

@Component({
  selector: 'pavweb-invoice-item-edit-modal',
  templateUrl: './invoice-item-edit-modal.component.html',
  styleUrls: ['./invoice-item-edit-modal.component.scss']
})
export class InvoiceItemEditModalComponent implements OnInit {

  private _item = new InvoiceItem();
  public taxSelector;

  providedTotal = 0;
  private givenTax: string;

  set item(item: InvoiceItem) {

    this._item = new InvoiceItem().deserialize(item);
    if (+item.taxPercentage > 0) {
      this.givenTax = item.taxPercentage;
      this.taxSelector = 'plus-tax';
    }
    this.providedTotal = this.getAmountTotal().toNumber();
  }

  get item(): InvoiceItem {
    return this._item;
  }

  @Input() company: Company;

  constructor(public bsModalRef: BsModalRef, public apiSvc: ApiService, private modalSvc: BsModalService) {

  }

  ngOnInit() {
  }

  getAmountTotal() {
    const amount = new BigNumber(this._item.amount || 0);
    let taxPercentage = new BigNumber(0);
    const providedTotal = new BigNumber(this.providedTotal || 0);

    if (this.taxSelector == 'plus-tax') {
      if (this.givenTax) {
        taxPercentage = new BigNumber(this.givenTax);
      } else {
        taxPercentage = new BigNumber(this.company.taxRate);
      }
    }

    const taxAmount = amount.multipliedBy(taxPercentage).div(100);

    if (this.taxSelector == 'inc-tax') {
      return providedTotal;
    }

    return amount.plus(taxAmount);
  }

  getTotal() {

    const quantity = new BigNumber(this._item.quantity || 1);

    return this.getAmountTotal().multipliedBy(quantity);

  }

  saveInvoiceItem(invoiceItemForm: NgForm) {
    if (invoiceItemForm.invalid) {
      Object.keys(invoiceItemForm.controls).forEach(field => {
        const control = invoiceItemForm.controls[field];
        control.markAsTouched({onlySelf: true});
      });
      return;
    }

    this.modalSvc.setDismissReason('saveChanges');

    if (this.taxSelector === 'inc-tax') {
      const providedTotal = new BigNumber(this.providedTotal);
      if (this.givenTax) {
        this.item.taxPercentage = this.givenTax;
      } else {
        this.item.taxPercentage = this.company.taxRate;
      }

      const taxRate = new BigNumber(this.item.taxPercentage).div('100');
      this.item.amount = providedTotal.div(new BigNumber(1).plus(taxRate)).toFixed(10);
    }

    this.bsModalRef.hide();
  }

  changedSelector($event) {
    if ($event === 'plus-tax' || $event === 'inc-tax') {
      return this._item.taxPercentage = this.company.taxRate;
    }
    if ($event === 'no-tax') {
      return this._item.taxPercentage = '0';
    }

  }
}

