import {Component} from '@angular/core';
import {Contact} from '../../../../models/Contact';
import {GiftVoucher} from '../../../../models/GiftVoucher';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {map} from 'rxjs/operators';
import {ApiService} from '../../../_services/api.service';
import {Observable} from 'rxjs';
import {GiftVoucherImage} from '../../../../models/GiftVoucherImage';

@Component({
  templateUrl: './add-gift-voucher-modal.component.html',
  styleUrls: ['add-gift-voucher-modal.component.scss']
})
export class AddGiftVoucherModalComponent {
  _giftVoucher: GiftVoucher;
  public _contacts: Contact[];
  public voucherImages$: Observable<GiftVoucherImage[]>;

  public form;

  set contacts(contacts) {
    this._contacts = contacts;
  }

  get contacts() {
    return this._contacts;
  }

  set giftVoucher(giftVoucher: GiftVoucher) {
    this._giftVoucher = giftVoucher;
  }

  get giftVoucher(): GiftVoucher {
    return this._giftVoucher;
  }

  constructor(public bsModalRef: BsModalRef, private modalSvc: BsModalService, private apiSvc: ApiService) {
    this.voucherImages$ = this.apiSvc.getGiftVoucherImages().pipe(map(x => x.data));
  }

  submitForm(form) {
    this.form = form;
    this.modalSvc.setDismissReason(AddGiftVoucherModalComponentReasons.ACCEPTED);
    this.bsModalRef.hide();
  }
}

export enum AddGiftVoucherModalComponentReasons {
  ACCEPTED = 'accepted'

}
