import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BookingParticipant} from '../../../models/BookingParticipant';
import {ContactWithRelationType} from '../../../models/ContactWithRelationType';
import {SubCourse} from '../../../models/SubCourse';
import {compareFunc} from '../../utils/Utils';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export const DEFAULT_VALUE_ACCESSOR: any = {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TicketItemComponent), multi: true};


@Component({
    selector: 'pavweb-ticket-item',
    templateUrl: './ticket-item.component.html',
    providers: [
      DEFAULT_VALUE_ACCESSOR,
      {provide: NG_VALIDATORS, useExisting: forwardRef(() => TicketItemComponent), multi: true}
    ]
  }
)
export class TicketItemComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private _onDestroyObserver$: Subject<any> = new Subject<any>();
  onValidationChange: () => void;
  disabled: boolean;
  @Input() availableContacts: ContactWithRelationType[];
  @Input() subCourses: SubCourse[];
  compareFunc = compareFunc;
  touched = false;
  onChange;
  bookingParticipant: BookingParticipantWithContactType = new BookingParticipantWithContactType();


  onTouched;

  @Output() changedTicketContact: EventEmitter<ContactWithRelationType> = new EventEmitter<ContactWithRelationType>();

  @ViewChild('form', {static: true}) form;


  @Output() onMembershipApplicationChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._onDestroyObserver$)).subscribe((changes) => {
      this.onChange(this.form.value);
      if (this.onValidationChange) {

        this.onValidationChange()
      }
    })
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    // console.log("writeValue",value);
    // if(value!=null)
    this.bookingParticipant = value;
  }

  onChangedTicketContact(contact) {
    this.changedTicketContact.next(contact);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return {invalid: 'this is not valid'}
    }
    return null;
  }

  ngOnDestroy(): void {
    this._onDestroyObserver$.next();
  }

  applyMembership(apply: boolean) {
    this.onMembershipApplicationChange.next(apply);
  }
}

export class BookingParticipantWithContactType extends BookingParticipant {
  contact: ContactWithRelationType;
}
