import {Component, OnInit} from '@angular/core';
import {Routes} from '@angular/router';

import {PAGES_MENU} from './pages.menu';
import {BaMenuService} from '../theme/services/baMenu';

@Component({
  selector: 'pages',
  template: `
    <ba-sidebar></ba-sidebar>

    <ba-page-top></ba-page-top>

    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-right"></div>
      <div class="al-footer-main clearfix">
        <div class="al-copy">Pavilion Web Ltd, All Rights Reserved</div>
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
  `
})
export class Pages implements OnInit {

  constructor(private _menuService: BaMenuService) {
  }

  ngOnInit() {
    this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
  }
}
