export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        data: {
          menu: {
            title: 'Dashboard',
            icon: 'ion-md-home',
            selected: false,
            expanded: false,
            order: 0,
            class: 'head-title'
          }
        },

      },
      {
        path: 'customers',
        data: {
          menu: {
            title: 'Customers',
            icon: 'ion-md-contacts',
            selected: false,
            expanded: false,
            order: 1,
            class: 'head-title'
          }
        },

      },
      {
        path: 'staff',
        data: {
          menu: {
            title: 'Staff',
            icon: 'ion-md-person',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: '',
            data: {
              menu: {
                title: 'Members',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'availability',
            data: {
              menu: {
                title: 'Availability',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'status',
            data: {
              menu: {
                title: 'Status',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'timeline',
            data: {
              menu: {
                title: 'Timeline',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'allocation',
            data: {
              menu: {
                title: 'Allocation',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          }
        ]
      },
      {
        path: 'course',
        data: {
          menu: {
            title: 'Courses',
            icon: 'ion-md-document',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: 'runs',
            data: {
              menu: {
                title: 'Add Course Date',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'list',
            data: {
              menu: {
                title: 'Course List',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'memberships',
            data: {
              menu: {
                title: 'Membership',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'gift-vouchers',
            data: {
              menu: {
                title: 'Gift Vouchers',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          }
        ]
      },
      {
        path: 'calendar',
        data: {
          menu: {
            title: 'Calendars',
            icon: 'ion-md-calendar',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: 'my',
            data: {
              menu: {
                title: 'My Calendar',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'staff',
            data: {
              menu: {
                title: 'Staff Calendar',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'resource',
            data: {
              menu: {
                title: 'Resource Calendar',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'course',
            data: {
              menu: {
                title: 'Calendar',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
        ]
      },
      {
        path: 'book',
        data: {
          menu: {
            title: 'Book',
            icon: 'ion-md-book',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: 'client',
            data: {
              menu: {
                title: 'Individual Book',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              }
            }
          },
          {
            path: 'company',
            data: {
              menu: {
                title: 'Company Book',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              }
            }
          },
          {
            path: 'partnerCompany',
            data: {
              menu: {
                title: 'Partner Book',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              }
            }
          },
        ]
      },
      {
        path: 'admin',
        data: {
          menu: {
            title: 'Admin',
            icon: 'ion-md-snow',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: 'invoices',
            data: {
              menu: {
                title: 'Invoices',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'health',
            data: {
              menu: {
                title: 'Health and Safety',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'reports',
            data: {
              menu: {
                title: 'Reports',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
        ]
      },
      {
        path: 'settings',
        data: {
          menu: {
            title: 'Settings',
            icon: 'ion-md-settings',
            selected: false,
            expanded: false,
            order: 2,
            class: 'head-title',
          },
        },

        children: [
          {
            path: 'locations',
            data: {
              menu: {
                title: 'Locations',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'resources',
            data: {
              menu: {
                title: 'Resources',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'resource-types',
            data: {
              menu: {
                title: 'Resource type',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'salesCategories',
            data: {
              menu: {
                title: 'Sale Catagories',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'companies',
            data: {
              menu: {
                title: 'System Companies',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'customer-companies',
            data: {
              menu: {
                title: 'Customer Companies',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'membershipTypes',
            data: {
              menu: {
                title: 'Membership Type',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'permissions',
            data: {
              menu: {
                title: 'Permissions',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'discount-codes',
            data: {
              menu: {
                title: 'Discount codes',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'email-templates',
            data: {
              menu: {
                title: 'Email templates',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'gift-voucher-images',
            data: {
              menu: {
                title: 'Gift Voucher Images',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'orders',
            data: {
              menu: {
                title: 'Orders',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
          {
            path: 'cancellationPolicies',
            data: {
              menu: {
                title: 'Terms&Conditions',
                icon: 'ion-ios-arrow-forward-outline',
                selected: false,
                expanded: false,
                order: 2,
              },
            },
          },
        ]
      }
    ]
  }
];
