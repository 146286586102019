import {Directive, HostBinding, OnInit} from '@angular/core';

import {BaThemeConfigProvider, isMobile} from '../../../theme';

@Directive({
  selector: '[baThemeRun]'
})
export class BaThemeRunDirective implements OnInit {

  private bodyElm: HTMLElementTagNameMap["body"];
  constructor(private _baConfig: BaThemeConfigProvider) {
    this.bodyElm = document.getElementsByTagName('body')[0];
  }

  public ngOnInit(): void {
    this._assignTheme();
    this._assignMobile();
  }

  private _assignTheme(): void {
    this._addClass(this._baConfig.get().theme.name);
  }

  private _assignMobile(): void {
    if (isMobile()) {
      this._addClass('mobile');
    }
  }

  private _addClass(cls: string) {
    this.bodyElm.classList.add(cls);
  }
}
