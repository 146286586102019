import {MembershipType} from './MembershipType';
import {Course} from './Course';

export class MembershipTypeA extends MembershipType {
  grade: string;
  allCoursesDiscount: string;
  largerDiscount: string;
  discountedCourses: Course[];
  freeCourses: Course[];
  type = 'membershipA';

  getCourseDiscount(course: Course) {
    if (this.freeCourses.find(x => x.id === course.id)) {
      return 100;
    }

    if (this.discountedCourses.find(x => x.id === course.id)) {
      return +this.largerDiscount;
    }

    return +this.allCoursesDiscount;
  }

  deserialize(input) {
    if (!input) {
      return this;
    }

    super.deserialize(input);

    this.grade = input.grade;
    this.allCoursesDiscount = input.allCoursesDiscount;
    this.largerDiscount = input.largerDiscount;

    if (input.discountedCourses) {
      this.discountedCourses = input.discountedCourses.map(item => {
        const discountedCourse = new Course().deserialize(item);
        return discountedCourse;
      });
    }

    if (input.freeCourses) {
      this.freeCourses = input.freeCourses.map(item => {
        const freeCourse = new Course().deserialize(item);
        return freeCourse;
      });
    }
    this.type = input.type;

    return this;

  }
}
