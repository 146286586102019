import {Company} from './Company';
import {CourseRun} from './CourseRun';
import {Course} from './Course';

export class PriceElement {
  id: number | string;
  description: string;
  amount: string;
  taxPercentage: string;
  applyTax: boolean;
  company: Company;
  course?: Course;
  courseRun?: CourseRun

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.description = input.description;
    this.amount = input.amount;
    this.taxPercentage = input.taxPercentage;
    this.applyTax = input.applyTax;

    return this;
  }
}
