import {Serializable} from './Serializable';

export class GeneratorDataItem implements Serializable<GeneratorDataItem> {
  id: number;
  startTime: string;
  duration: number;
  weekday: number;
  weekIdx: number;

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.startTime = input.startTime;
    this.duration = input.duration;
    this.weekday = input.weekday;
    this.weekIdx = input.weekIdx;
    return this;
  }
}
