import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ApiService} from '../../_services/api.service';
import {map, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../_services';
import {TokenInterceptor} from '../../_interceptors/token.interceptor';
import {Contact} from '../../../models/Contact';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class PermissionsGuard implements CanActivate {

  constructor(private router: Router, private apiSvc: ApiService, private profileSvc: AuthenticationService, private toastySvc: ToastrService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest(
      this.apiSvc.loadPermissions(),
      TokenInterceptor.decodedToken$
    ).pipe(
      map(([permissions, decodedToken]) => {
        
        const processedUrl = state.url.replace('/pages/', '');
        const currentUser = <Contact>decodedToken;        
        return !(permissions[processedUrl] && permissions[processedUrl][currentUser.staffMember.role] == false);
      }),
      tap(result => {
        if (!result) {
          this.toastySvc.warning('Navigation Forbidden')
        }
      })
    )
  }
}
