import {Component, EventEmitter, Input, Output} from '@angular/core';
import {humanizeBytes, UploadFile, UploadOutput} from 'ngx-uploader';

@Component({
  selector: 'pavweb-file-uploader',
  templateUrl: './fileUploader.component.html',
  styleUrls: ['./fileUploader.component.scss']
})
export class FileUploaderComponent {

  @Input() multiple = true;
  @Input() acceptedFiles;
  @Input() autoUpload = false;
  @Input() uploadOptions: any;

  @Output() fileUploaded = new EventEmitter();
  @Output() fileAdded = new EventEmitter<UploadFile>();
  files: UploadFile[];
  uploadInput: EventEmitter<any>;
  humanizeBytes: Function;
  dragOver: boolean;

  constructor() {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<any>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      if (this.autoUpload) {

        if (this.uploadOptions) {
          this.uploadInput.emit(this.uploadOptions);
        }

      }

    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
      this.fileAdded.next(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.fileUploaded.next(output);
    }
  }

  startUpload(): void {
    const event = {
      type: 'uploadAll',
      url: 'http://ngx-uploader.com/upload',
      method: 'POST',
      data: {foo: 'bar'}
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
  }

  removeAllFiles(): void {
    this.uploadInput.emit({type: 'removeAll'});
  }
}
