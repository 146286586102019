import {Serializable} from './Serializable';

export class CourseType implements Serializable<CourseType> {
  id: number;
  name: string;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.name = input.name;
    return this;
  }
}
