import {Component, Input, OnInit, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgModel} from '@angular/forms';
import {PopoverDirective} from 'ngx-bootstrap';


@Component({
  selector: 'date-time-picker',
  template:
      `
    <div class="onclick-menu">
      <ng-template #template>
        <div class="row" [ngClass]="{'shown': shown}" style="background: lightblue">
        </div>
        <div class="row">
          <button type="button" class="btn btn-success" (click)="pop.hide()">Ok</button>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [NgModel]
})

export class PavwebDateTimePickerComponent implements ControlValueAccessor, OnInit {

  @ViewChild('pop', {static: true}) popOver: PopoverDirective;
  @Input() milliseconds: boolean;
  @Input() format: string;
  public cd: NgModel;
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  internalDate: Date;
  internalTime: Date;
  internalDateTime: Date;


  public shown = false;

  public constructor(@Self() cd: NgModel) {

    this.cd = cd;
    cd.valueAccessor = this;
  }

  inputFocus() {
    this.popOver.show();
  }

  ngOnInit() {

    this.internalDate = this.internalDateTime;
    this.internalTime = this.internalDateTime;
  }


  writeValue(obj: any): void {

    if (obj === this.internalDateTime) {
      return;
    }
    if (obj && obj instanceof Date) {
      this.internalDateTime = obj;
      this.internalDate = this.internalDateTime;
      this.internalTime = this.internalDateTime;
      return;
    }
    this.internalDateTime = obj ? new Date(obj) : void 0;

  }


  registerOnChange(fn: any): void {

    this.onChange = fn;
  }


  registerOnTouched(fn: any): void {

    this.onTouched = fn;
  }

  updateDateTime() {

    let newDate: Date = new Date();
    newDate.setTime(this.internalDate.getTime());
    newDate.setHours(this.internalTime.getHours());
    newDate.setMinutes(this.internalTime.getMinutes());
    newDate.setSeconds(0);
    newDate.setMilliseconds(this.internalTime.getMilliseconds());
    this.internalDateTime = newDate;
    this.onChange(this.internalDateTime);
    if (this.milliseconds) {
      this.cd.viewToModelUpdate(newDate.getTime());
    } else {
      this.cd.viewToModelUpdate(newDate);
    }
  }


  get date(): Date {

    return this.internalDate;
  }

  set date(date: Date) {

    this.internalDate = date;
    this.updateDateTime();
  }


  get time(): Date {

    return this.internalTime;
  }

  set time(time: Date) {
    this.internalTime = time;
    this.updateDateTime();
  }
}
