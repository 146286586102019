import {CanDeactivate} from '@angular/router';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '../../../models/ComponentCanDeactivate';
import { Injectable } from "@angular/core";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>
}


@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      return confirm('You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    }
  }
}
