import {Serializable} from './Serializable';

export class Currency implements Serializable<Currency> {
  code: string;
  name: string;
  symbol: string;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.code = input.code;
    this.name = input.name;
    this.symbol = input.symbol;
    return this;
  }
}
