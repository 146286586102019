/**
 * Created by fizda on 26/03/2017.
 */
import {Company} from './Company';
import {BillingAddress} from './BillingAddress';
import {StaffMember} from './StaffMember';
import {Serializable} from './Serializable';

export class Contact implements Serializable<Contact> {
  id?: number;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  roles: string[];
  token: string;
  company: Company;
  billingAddress: BillingAddress;
  profilePicture?: string;
  enabled: boolean = true;
  mobile: string;
  phone: string;
  swimmer: boolean = null;
  staffMember: StaffMember;
  specialOffersSubscription: boolean;
  newsLetterSubscription: boolean;
  notes: string;
  userName: string;
  dob: string;
  emergencyContactNumber: string;
  medicalConditions: string;

  constructor() {
    this.company = new Company();
    this.billingAddress = new BillingAddress();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.email = input.email;
    this.roles = input.roles;
    this.token = input.token;
    this.company = new Company().deserialize(input.company);
    this.billingAddress = new BillingAddress().deserialize(input.billingAddress);
    this.profilePicture = input.profilePicture;
    this.enabled = input.enabled;
    this.mobile = input.mobile;
    this.phone = input.phone;
    this.swimmer = input.swimmer;
    this.staffMember = new StaffMember().deserialize(input.staffMember);
    this.specialOffersSubscription = input.specialOffersSubscription;
    this.newsLetterSubscription = input.newsLetterSubscription;

    return this;
  }
}

export interface ContactJSON {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  token: string;
}

