import {Component, EventEmitter, Output} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'nga-confirm-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
    </div>
    <div class="modal-body">
      <div>
        {{message}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="onCancel()">{{noText}}</button>
      <button type="button" class="btn btn-success" (click)="onOk()">{{yesText}}</button>
    </div>`
})
export class ConfirmModalComponent {
  public message: string;
  public title: string;
  public yesText: string = 'Ok';
  public noText: string = 'Cancel';

  @Output() onClick = new EventEmitter<boolean>();

  constructor(public bsModalRef: BsModalRef, private modalSvc: BsModalService) {
    this.modalSvc.setDismissReason(ConfirmModalReason.NO);
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onOk() {
    this.modalSvc.setDismissReason(ConfirmModalReason.YES);
    this.bsModalRef.hide();
  }

}

export enum ConfirmModalReason {
  YES = 'yes',
  NO = 'no'
}
