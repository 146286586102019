import {Injectable} from '@angular/core';
import {isNumber} from 'util';


@Injectable()
export class JsonToCsvService {

  constructor() {
    // Blank Constructor for Demo Purpose
  }

  // Download CSV
  download(data: any, filename: string) {
    let csvData = this.ConvertToCSV(data);
    let a: any = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let blob = new Blob([csvData], {type: 'text/csv'});
    a.href = window.URL.createObjectURL(blob);

    let isIE = !!(<any> document).documentMode;

    if (isIE) {
      let retVal = navigator.msSaveBlob(blob, filename + '.csv');
    }
    else {
      a.download = filename + '.csv';
    }
    // If you will any error in a.download then dont worry about this.
    a.click();
  }


  // convert Json to CSV data
  ConvertToCSV(objArray: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') {
          line += ','
        }

        if (isNumber(array[i][index])) {
          line += `${array[i][index]}`;
          continue;
        }

        line += `"${array[i][index]}"`;
      }

      str += line + '\r\n';
    }

    return str;
  }
}
