import {MembershipType} from './MembershipType';
import {Course} from './Course';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';

export class MembershipBookingLimitation implements Serializable<MembershipBookingLimitation> {
  id: number | string;
  membershipType: MembershipType;
  courses: Course[];
  limitInAdvance: number;
  limitMonday: number;
  limitTuesday: number;
  limitWednesday: number;
  limitThursday: number;
  limitFriday: number;
  limitSaturday: number;
  limitSunday: number;

  constructor() {
    this.id = UUID();
    this.membershipType = new MembershipType();
    this.courses = [];
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    if (!(input.membershipType instanceof MembershipType)) {
      this.membershipType = new MembershipType().deserialize(input.membershipType);
    } else {
      this.membershipType = input.membershipType;
    }
    this.courses = [];
    if (input.courses) {
      this.courses = input.courses.map(item => {
        return new Course().deserialize(item);
      });
    }
    this.limitInAdvance = input.limitInAdvance;
    this.limitMonday = input.limitMonday;
    this.limitTuesday = input.limitTuesday;
    this.limitWednesday = input.limitWednesday;
    this.limitThursday = input.limitThursday;
    this.limitFriday = input.limitFriday;
    this.limitSaturday = input.limitSaturday;
    this.limitSunday = input.limitSunday;

    return this;
  }
}
