import { Environment } from '@angular/compiler-cli/src/ngtsc/typecheck/src/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';

import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import {GeneratorDataItem} from '../../../../models/GeneratorDataItem';
import {ResourceCalendar} from '../resourceCalendar/ResourceCalendar';

@Component({
  selector: 'nga-week-slots-picker',
  templateUrl: './course-week-slots-picker.html',
  styleUrls: ['./course-week-slots-picker.scss']
})
export class CourseWeekSlotsPicker {
  _slotDuration: number;
  _calendarColor: string;
  _generatorDataItems: GeneratorDataItem[];

  @Input() set calendarColor(calendarColor) {
    this._calendarColor = calendarColor;
    if (this.calendar) {
      this.calendar.fullCalendar('option', 'eventColor', this._calendarColor);
    }
  }

  @Input() set generatorDataItems(generatorItems: GeneratorDataItem[]) {
    this._generatorDataItems = generatorItems;

    if (this.calendar) {
      this.calendar.fullCalendar('refetchEvents');
    }
  };

  @Input() set slotDuration(duration) {
    if (!duration) {
      return;
    }
    this._slotDuration = duration;
    //check if any events don't match the event duration..and modify them..and inform about it
    this._generatorDataItems.map((generatorDataItem) => {
      if (generatorDataItem.duration % this._slotDuration != 0) {
        const modifiedGenerator = {
          ...generatorDataItem,
          id: 1 - parseInt(moment().format('x')),
          duration: Math.ceil(generatorDataItem.duration / this._slotDuration) * this._slotDuration
        };

        this.eventModified.next({oldEvent: generatorDataItem, newEvent: modifiedGenerator});
      }
      return generatorDataItem;
    });

    if (this.calendar) {
      this.calendar.fullCalendar('option', 'snapDuration', {minutes: duration});
    }
  };

  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();
  @Output() eventModified: EventEmitter<{ oldEvent: any, newEvent: any }> = new EventEmitter();
  @Output() eventCreated: EventEmitter<any> = new EventEmitter();

  calendarOptions;
  calendar: ResourceCalendar;

  constructor() {
    this.calendarOptions = {
      height: '1700px',
      slotDuration: '00:15:00',
      contentHeight: 700,
      scrollTime: '08:30:00',
      fixedWeekCount: false,
      defaultView: 'agendaWeek',
      schedulerLicenseKey: environment.schedulerLicenseKey,
      defaultDate: moment().day(-6).toDate(),
      columnFormat: 'dddd',
      groupByResource: true,
      eventClick: this.eventClick.bind(this),
      firstDay: 1,
      header: false,
      allDaySlot: false,
      editable: true,
      eventLimit: true, // allow "more" link when too many events
      selectable: true,
      selectHelper: true,
      overlap: false,
      eventOverlap: false,
      eventDrop: this.eventModify.bind(this),
      eventResize: this.eventModify.bind(this),
      selectOverlap: false,
      eventColor: this._calendarColor,
      select: (start, end, allDay) => {

        const newEvent = {
          startTime: start.format('HH:mm'),
          duration: end.diff(start, 'minutes'),
          id: 1 - parseInt(moment().format('x')),
          weekday: start.isoWeekday(),
          weekIdx: 1
        };
        this.calendar.fullCalendar('unselect');

        this.eventCreated.next(newEvent);
      },
      events: (startDate, endDate, tz, callback) => {
        const internalEvents = this._generatorDataItems.map((eventItem) => {
          const startMoment = moment(eventItem.startTime, 'HH:mm').day(eventItem.weekday - 7);
          return {
            start: startMoment.toDate(),
            end: startMoment.clone().add(eventItem.duration, 'minutes'),
            id: eventItem.id,
          }
        });

        callback(internalEvents);
      },
      eventRender: (event, element, view) => {
        const heightPerMinute = 1.46666667;
        const heightPerSlotDuration = this._slotDuration * heightPerMinute;
        element.css('background-image', 'repeating-linear-gradient(0deg, #fff, #fff 1px, transparent 1px, transparent ' + heightPerSlotDuration + 'px)');
        element.css('background-size', '100px ' + heightPerSlotDuration + 'px');
        element.css('border-bottom', '1px solid #fff');
      }
    };
  }


  eventModify(event, delta, revertFunc, jsEvent, ui, view) {
    //check the difference between the start and the new end
    const startTime = event.start;
    const endTime = event.end;

    const duration = endTime.diff(event.start, 'minutes');
    if ((duration % this._slotDuration) != 0) {
      revertFunc();
      return;
    }

    const modifiedEvent = {
      startTime: startTime.format('HH:mm'),
      duration: endTime.diff(event.start, 'minutes'),
      id: 1 - parseInt(moment().format('x')),
      weekday: event.start.isoWeekday(),
      weekIdx: 1,
    };

    this.eventModified.next({oldEvent: event, newEvent: modifiedEvent});
  }

  onCalendarInit(calendar) {
    this.calendar = calendar;
  }

  eventClick(event) {
    this.eventDeleted.next(event);
  }

}
