import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BookingParticipant} from '../../../models/BookingParticipant';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContactWithRelationType} from '../../../models/ContactWithRelationType';


@Component({
  selector: 'pavweb-ticket-list',
  templateUrl: './ticket-list.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TicketListComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => TicketListComponent), multi: true}
  ]
})
export class TicketListComponent implements ControlValueAccessor, Validator, OnInit {
  _onDestroyObserver$: Subject<any> = new Subject<any>();

  onTouched: any;
  disabled: boolean;
  tickets: any[] = [];
  _participants: BookingParticipant[] = [];
  @Input() availableContacts;
  @Input() subCourses;
  private _form: NgForm;

  @ViewChild('ticketsForm', {static: true}) set form(form: NgForm) {
    this._form = form;
    this.form.valueChanges.pipe(takeUntil(this._onDestroyObserver$)).subscribe((value) => {
      if (this.onChange) {
        this.onChange(this._participants);
      }
      if (this.onValidationChange) {
        this.onValidationChange();
      }
    })
  };

  get form() {
    return this._form;
  }

  private onChange: any;
  private onValidationChange: () => void;

  @Input() set availableTickets(availableTickets: number) {
    this.tickets = new Array(availableTickets);
  };

  get availableTickets(): number {
    return this.tickets.length;
  }

  @Output() onClientContactAdd: EventEmitter<Function> = new EventEmitter<Function>();

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this._participants = obj;
  }


  changedTicketContact(newContact, ticket) {
    if (ticket.contact && ticket.contact.id != -1) {
      this.availableContacts.push(ticket.contact);
      this.sortContacts();
    }
    ticket.contact = newContact;
    ticket.membership = null;

    if (newContact) {
      ticket.membership = newContact.membership;
      if (newContact.id == -1) {
        return this.onClientContactAdd.next((contact) => {
          const foundIdx = this._participants.findIndex(x => x.id == ticket.id);
          const newTicket = new BookingParticipant();
          newTicket.subCourse = ticket.subCourse;
          newTicket.contact = contact;
          newTicket.membership = contact.membership;
          this._participants[foundIdx] = newTicket;
          this.onChange(this._participants);
        })
      }
      this.availableContacts = this.availableContacts.filter((contact) => {
        return contact.id !== newContact.id
      });
    }

    this.onChange(this._participants);
  }


  sortContacts() {
    this.availableContacts = this.availableContacts.sort((a, b) => {

      if (a.type == b.type) {
        return (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName);
      }

      if (a.type == 'self') {
        return -1;
      }

      if (b.type == 'self') {
        return 1;
      }

      if (a.type == 'Partner') {
        return -1;
      }

      if (b.type == 'Partner') {
        return 1;
      }

      if (a.type == 'Child') {
        return -1;
      }

      if (b.type == 'Child') {
        return 1;
      }

      if (a.type == 'Friend') {
        return -1;
      }

      return 1;
    })
  }

  changedTickets($event) {
    const difference = $event.target.value - this._participants.length;
    if (difference === 0) {
      return;
    }

    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this._participants.push(new BookingParticipant());
      }
      return;
    }

    this._participants.slice(difference).forEach(participant => {
      this.changedTicketContact(null, participant);
    });
    this._participants = this._participants.slice(0, difference);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return {invalid: 'this is not valid'}
    }
    return undefined;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._onDestroyObserver$.next();
  }

  changedMembershipApplication(apply, bookingParticipant: BookingParticipant) {
    if (apply) {
      bookingParticipant.membership = (<ContactWithRelationType>bookingParticipant.contact).membership;
    } else {
      bookingParticipant.membership = null;
    }
    this.onChange(this._participants);
  }
}
